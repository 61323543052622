import "flatpickr/dist/themes/airbnb.css";
import "./Calendar.css";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { useEffect, useState } from "react";

const d = new Date();

function Calendar({ onSelectedDate, onMonthChange, appointmentCreated }) {
  const [options, setOptions] = useState({
    dateFormat: "Y-m-d",
    inline: true,
    enable: [],
    disable: [],
    locale: Italian,
    minDate: "today",
    maxDate: new Date(d.getFullYear(), d.getMonth() + 2, 0),
  });

  const getBookingDates = async (currentMonth, currentYear) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/getBookingDates?month=${
        currentMonth === 0 ? currentMonth : currentMonth + 1
      }&year=${currentYear}`
    );
    return await response.json();
  };

  const handleMonthChange = (selectedDates, dateStr, instance) => {
    if (instance !== null) {
      onMonthChange();
      getBookingDates(instance.currentMonth, instance.currentYear).then(
        (data) => {
          setOptions({
            enable: data.data.availableDates,
            disable: data.data.disableDates,
          });
        }
      );
    }
  };

  useEffect(() => {
    getBookingDates(d.getMonth(), d.getFullYear()).then((data) => {
      setOptions({
        enable: data.data.availableDates,
        disable: data.data.disableDates,
      });
    });
  }, []);

  useEffect(() => {
    if (appointmentCreated) {
      onSelectedDate(appointmentCreated);
    } // eslint-disable-next-line
  }, [appointmentCreated]);

  return (
    <>
      <div className="booking-confabitare">
        <Flatpickr
          options={options}
          onMonthChange={(selectedDates, dateStr, instance) => {
            handleMonthChange(selectedDates, dateStr, instance);
          }}
          onChange={(selectedDates, dateStr, instance) => {
            onSelectedDate(dateStr);
          }}
        />
      </div>
    </>
  );
}

export default Calendar;
