import styles from "./App.module.scss";
import { useState, useEffect } from "react";

function SuccessMessage({ message }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (message !== "") {
      setShow(true);
    }
  }, [message]);

  if (show) {
    return (
      <>
        <div
          className={`${styles.alert} ${styles["alert-success"]} ${styles["alert-dismissible"]}`}
        >
          <button
            type="button"
            className={`${styles.close}`}
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <p>{message}</p>
        </div>
      </>
    );
  }
}

export default SuccessMessage;
