import styles from "./App.module.scss";
import { useState, useRef } from "react";
import Calendar from "./Calendar";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

function App() {
  const captchaRef = useRef(null);
  const [services, setServices] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [isAppointmentCreated, setIsAppointmentCreated] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    date: "",
    slotSelected: "",
    serviceSelected: "",
    name: "",
  });

  const setBooking = async (slots) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(slots),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/setBooking`,
      requestOptions
    );
    return await response.json();
  };

  const getAvailableSlots = async (date) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/getAvailableSlots?date=${date}`
    );
    return await response.json();
  };
  const handleMonthChange = () => {
    setSlots([]);
    setIsAppointmentCreated("");
    setError("");
    setSuccess("");
  };

  const verifyToken = async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/verifyCaptchaToken?response=${token}`
    );
    return await response.json();
  };

  const handleSelectedDate = (dateStr) => {
    setError("");
    //setSuccess("");
    setFormData({
      ...formData,
      date: dateStr,
    });
    getAvailableSlots(dateStr).then((data) => {
      setSlots(data.data.slots);
      setServices(data.data.services);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");
    if (
      formData.slotSelected === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.name === "" ||
      formData.date === "" ||
      formData.serviceSelected === ""
    ) {
      e.preventDefault();
      e.stopPropagation();
      setSuccess("");
      setError("Compilare correttamente i campi");
      return false;
    }
    const token = await captchaRef.current.executeAsync();
    if (token) {
      verifyToken(token).then((data) => {
        if (data.data) {
          setIsAppointmentCreated("");
          setButtonIsLoading(true);
          setBooking(formData).then((data) => {
            if (data.data) {
              setIsAppointmentCreated(formData.date);
              setButtonIsLoading(false);
              setError("");
              setSuccess("Appuntamento creato con successo");
              resetCaptcha();
            } else {
              setSuccess("");
              setError("Prenotazione non possibile");
              setButtonIsLoading(false);
            }
          });
        } else {
          setSuccess("");
          setError("Captcha non valido");
          setButtonIsLoading(false);
        }
      });
    } else {
      setSuccess("");
      setError("Token non valido");
      setButtonIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handlePhoneChange = (e) => {
    setFormData({ ...formData, phone: e.target.value });
  };

  const handleNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handleSlotSelection = (e) => {
    setFormData({ ...formData, slotSelected: e.target.value });
  };

  const handleServiceSelection = (e) => {
    setFormData({ ...formData, serviceSelected: e.target.value });
  };

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captchaRef.current.reset();
  };

  return (
    <>
      {error !== "" ? <ErrorMessage message={error} /> : <></>}
      {success !== "" ? <SuccessMessage message={success} /> : <></>}
      <div
        className={`${styles.container} ${styles["card"]}`}
        style={{ minHeight: 560 }}
      >
        <div className={`${styles.row} ${styles["mt-2"]} `}>
          <div className={`${styles["col-12"]} ${styles["text-center"]}`}>
            <div className={`${styles.h2}`}>Prenota un appuntamento</div>
          </div>
        </div>
        <div className={`${styles.row} ${styles["mt-3"]}`}>
          <div className={`${styles["col-12"]} ${styles["col-lg-6"]}`}>
            <div
              className={`${styles["d-flex"]} ${styles["align-items-center"]} ${styles["flex-column"]}`}
            >
              <div className={`${styles["text-center"]}`}>
                <strong>Seleziona una data</strong>
              </div>
              <Calendar
                appointmentCreated={isAppointmentCreated}
                onMonthChange={() => handleMonthChange()}
                onSelectedDate={(dateStr) => handleSelectedDate(dateStr)}
              />
            </div>
          </div>
          <div
            className={`${styles["col-12"]} ${styles["col-lg-6"]} ${styles["mt-3"]}`}
          >
            <div
              className={`${styles["mx-4"]} ${styles["mb-4"]}`}
              style={{ display: !slots?.length ? "none" : "" }}
            >
              <div className={`${styles["form-group"]}`}>
                <label>Nome Cognome</label>
                <input
                  type="name"
                  className={`${styles["form-control"]}`}
                  required
                  value={formData.name}
                  onChange={handleNameChange}
                />
              </div>
              <div className={`${styles["form-group"]}`}>
                <label>Indirizzo E-mail</label>
                <input
                  type="email"
                  className={`${styles["form-control"]}`}
                  required
                  value={formData.email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className={`${styles["form-group"]}`}>
                <label>Telefono</label>
                <input
                  type="tel"
                  className={`${styles["form-control"]}`}
                  required
                  value={formData.phone}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className={`${styles["form-group"]}`}>
                <label>Seleziona un orario disponibile</label>
                <br />
                {slots?.map((slot, i) => (
                  <div
                    key={i}
                    className={`${styles["form-check"]} ${styles["mb-2"]} ${styles["mt-2"]}`}
                  >
                    <input
                      className={`${styles["form-check-input"]}`}
                      type={"radio"}
                      id={`slot_${i}`}
                      disabled={slot.isAvailable === 1 ? false : true}
                      value={slot.slot}
                      checked={formData.slotSelected === slot.slot}
                      onChange={handleSlotSelection}
                      required
                    />
                    <label
                      className={`${styles["form-check-label"]}`}
                      htmlFor={`slot_${i}`}
                    >
                      {" "}
                      {slot.slot}
                    </label>
                  </div>
                ))}
              </div>
              <div className={`${styles["form-group"]}`}>
                <label>
                  Seleziona la modalità:
                </label>
                <br />
                {services?.map((service, i) => (
                  <div
                    key={i}
                    className={`${styles["form-check"]} ${styles["mb-2"]} ${styles["mt-2"]}`}
                  >
                    <input
                      className={`${styles["form-check-input"]}`}
                      type={"radio"}
                      id={`service_${i}`}
                      value={service}
                      checked={formData.serviceSelected === service}
                      onChange={handleServiceSelection}
                      required
                    />
                    <label
                      className={`${styles["form-check-label"]}`}
                      htmlFor={`service_${i}`}
                    >
                      {" "}
                      {service}
                    </label>
                  </div>
                ))}
              </div>
              <div>
                <button
                  type="button"
                  className={`${styles["btn"]} ${styles["btn-primary"]}`}
                  disabled={buttonIsLoading || !slots?.length}
                  onClick={handleSubmit}
                >
                  {buttonIsLoading
                    ? "Richiesta appuntamento in corso…"
                    : "Richiedi un appuntamento"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTHA_API_KEY}
        ref={captchaRef}
        size="invisible"
        onExpired={resetCaptcha}
      />
    </>
  );
}

export default App;
